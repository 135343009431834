<template>
	<div id="app">
		<transition :name="ani">
			<router-view class="router" />
		</transition>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				ani: "left-fade",
			}
		},
		watch: {
			'$route': function() {
				if (this.$router.isBack) {
					this.ani = "right-fade";
					this.$router.isBack = false;
				} else this.ani = "left-fade";
			}
		},
		computed: {
			token() {
				return this.$store.state.token;
			},
			meta() {
				return this.$route.meta;
			},
		},
		methods: {

		},
		mounted() {

		},
		created() {

		},
	}
</script>

<style>
	#app {
		font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, Segoe UI, Arial, Roboto, 'PingFang SC', 'miui', 'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	* {
		overflow-scrolling: touch;
		-webkit-overflow-scrolling: touch;
	}

	body {
		background-color: white;
	}

	.van-nav-bar {
		background-color: #2d3033;
	}

	.van-nav-bar .van-icon {
		color: #555555;
	}

	.type_contant {
		margin-top: 46px;
		margin-bottom: 50px
	}

	.contant {
		margin-top: 85px;
		margin-bottom: 75px;
	}

	.top_header {
		position: fixed;
		width: 100%;
		z-index: 1;
	}

	.center_text {
		text-align: center;
	}

	.van-doc-title {
		margin: 0;
		padding: 32px 16px 16px;
		color: rgba(69, 90, 100, 0.6);
		font-size: 14px;
		line-height: 16px;
		padding-top: 20px;
	}

	.jb {
		margin-top: -1px;
		background-image: linear-gradient(#2d3033, #ffffff);
		height: 20px;
	}

	.router {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transition: all cubic-bezier(.55, 0, .1, 1) .5s;
	}

	.left-fade-leave-to {
		transform: translateX(-100%);
	}

	.left-fade-enter {
		transform: translateX(100%);
	}

	.right-fade-leave-to {
		transform: translateX(100%);
	}

	.right-fade-enter {
		transform: translateX(-100%);
	}

	.top-fade-leave-to {
		transform: translateY(100%);
	}

	.top-fade-enter {
		transform: translateY(-100%);
	}

	.bkblue {
		background-color: #2d3033;
	}

	.bkblue::after {
		border-bottom-width: 0px;
	}

	.blue {
		color: #2d3033;
	}

	.oldblue {
		color: #1989fa;
	}

	.van-button {
		margin: 10px;
	}

	.van-button--info {
		color: #555555;
		background-color: #2d3033;
		border: 1px solid #2d3033;
	}

	.black {
		color: #dddddd;
	}

	.gray {
		color: #f2f3f5;
	}

	.yellow {
		color: #2d3033;
	}

	.red {
		color: red;
	}

	.line {
		-webkit-text-stroke: 1px black;
	}

	.mw60 {
		min-width: 60%;
	}

	.w100 {
		width: 100%;
	}

	.h100 {
		height: 100%;
	}

	.custom-title {
		padding-left: 5px;
	}

	.van-progress {
		background: transparent;
	}

	.center {
		text-align: center;
	}

	.list-group {
		overflow-x: hidden;
	}

	.van-grid-item__text {
		font-size: 15px;
	}
	
	.button-tea{			background-color:#16a085; color:#FFFFFF;}
	.button-tea:hover{		background-color:#1abc9c;}
	.green-3d{				border-bottom:solid 4px #1e8248;}
	.button-green{			background-color:#27ae60; color:#FFFFFF;}
	.button-green:hover{	background-color:#2ecc71;}
	.blue-3d{				border-bottom:solid 4px #194e70;}
	.button-blue{			background-color:#2980b9; color:#FFFFFF;}
	.button-blue:hover{		background-color:#3498db;}
	.magenta-3d{			border-bottom:solid 4px #602e75;}
	.button-magenta{		background-color:#8e44ad; color:#FFFFFF;}
	.button-magenta:hover{	background-color:#9b59b6;}
	.dark-3d{				border-bottom:solid 4px #19232d;}
	.button-dark{			background-color:#2c3e50; color:#FFFFFF;}
	.button-dark:hover{		background-color:#34495e;}
	.yellow-3d{				border-bottom:solid 4px #ba970b;}
	.button-yellow{			background-color:#f1c40f; color:#1f1f1f;}
	.button-yellow:hover{	background-color:#f39c12;}
	.orange-3d{				border-bottom:solid 4px #9d3e00;}
	.button-orange{			background-color:#d35400; color:#FFFFFF;}
	.button-orange:hover{	background-color:#e67e22;}
	.red-3d{				border-bottom:solid 4px #8a281e;}
	.button-red{			background-color:#c0392b; color:#FFFFFF;}
	.button-red:hover{		background-color:#e74c3c;}
	.light-3d{				border-bottom:solid 4px #868a8c;}
	.button-light{			background-color:#bdc3c7; color:#1f1f1f;}
	.button-light:hover{	background-color:#ecf0f1;}
	.grey-3d{				border-bottom:solid 4px #474d4e;}
	.button-grey{			background-color:#7f8c8d; color:#FFFFFF;}
	.button-grey:hover{		background-color:#95a5a6;}
	
</style>
