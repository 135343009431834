import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import('@/views/Home.vue'),
		meta: {}
	}
]

const router = new VueRouter({
  mode:"history",
  routes,
  scrollBehavior (to, from, savedPosition) {return { x: 0, y: 0 }}
})

VueRouter.prototype.go=function(n){
  this.isBack=true;
  window.history.go(n);
}

VueRouter.prototype.backpush=function(n){
  this.isBack=true;
  this.push(n);
}

export default router
