import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ajax from './ajax'

import "font-awesome/css/font-awesome.css"

import {
	Popup,
	Button,
	NavBar,
	Divider
} from 'vant';

Vue.use(Popup);
Vue.use(Button);
Vue.use(NavBar);
Vue.use(Divider);


Vue.prototype.$ajax = ajax
// Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

try{
	window.apiready = function() {
		api.setStatusBarStyle({
			style:'light',
			color:'#ffcd00'
		});
	};
}catch(e){alert(e)}