import axios from "axios"
import router from '../router'
import { Toast } from 'vant';
import { Notify } from 'vant';

axios.defaults.timeout = 10000;

export default {
	apost(data, show=false){
		return new Promise((resolve, reject)=>{
			axios.post("/gettext.php", data).then((ret) => {
				resolve(ret.data);
			}).catch(err=>{
				this.fail('网络异常');
			});
		})
	},

	loading(str=''){
		Toast.loading({message: str, forbidClick: true});
	},

	done(str='已完成'){
		Toast.success({message: str, forbidClick: true});
	},

	fail(str=''){
		Toast.fail({message: str, forbidClick: true});
	},

	clear(){
		Toast.clear();
	},

	wrong(str){
		this.fail(str);
		// window.history.back();
	},

	success(str='操作成功'){
		Notify({ type: 'success', message: str });
	}
}